import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import routerAdmin from './routerAdmin';
import jwtDecode from 'jwt-decode';

Vue.use(VueRouter);

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {requireAuth: false},
    },
    {
        path: '/login',
        component: () => import('@/views/spoAdmin/login.vue'),
        meta: {requireAuth: false},
    },
    {
        path: "*",
        redirect: "/404",
        meta: {requireAuth: false},
    },
    {
        path: "/404",
        component: resolve => require.ensure([], () => resolve(require('@/views/notFound.vue'))),
        meta: {requireAuth: false},
    },
    {
        path: "/lackPermissions",
        component: resolve => require.ensure([], () => resolve(require('@/views/lackPermissions.vue'))),
        meta: {requireAuth: false},
    },
    ...routerAdmin,
];

const router = new VueRouter({routes});

if (sessionStorage.getItem('token')) {
    store.commit('set_token', {
        token: sessionStorage.getItem('token'),
        status: true
    });
}
store.commit('set_serverKey', sessionStorage.getItem('serverKey'));
store.commit('set_key', {pkcsType: 'pkcs8', pkcsSize: '1024'});

//路由守卫: 确保用户只能访问有权限的路由

router.beforeEach((to, from, next) => {
    // to.matched: 该数组中保存着匹配到的所有路由信息
    // console.log(to)
    store.commit('CLAER_CANCEL')
    if (to.meta.requireAuth) {
        if (store.state.token.token) {
            if (to.meta.roles.length>0) {
                const userTypes=jwtDecode(store.state.token.token).userType
                if (to.meta.roles) {
                    userTypes.forEach(e=>{
                        if (to.meta.roles.includes(e)) {
                            next() //有权限, 有token, 直接进入
                            return //找到满足项, 退出循环
                        // }else{next({path: "/lackPermissions"})} // 没有权限, 报权限不足
                        }else{next(false)} // 没有权限, 不跳转
                    })
                } else {next({path: "/404"})} // 权限为空
            } else {next()}
        } else {next({path: '/login', query: {redirect: to.fullPath}})}// 有权限没有token, 先登录
    } else {next()} //没有权限限制, 直接进入
});

export default router;
