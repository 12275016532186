import Vue from 'vue';
import Vuex from 'vuex';
import NodeRSA from 'node-rsa';
import Axios from 'axios';

Vue.use(Vuex);

const tokenModule = {
    state: {token: '', status: ''},
    mutations: {
        //储存token方法
        //设置token等于外部传进来的值
        set_token(state, tokenDetail) {
            state.token = tokenDetail.token;
            state.status = tokenDetail.status;
            sessionStorage.token = tokenDetail.token;
        },
        del_token(state) {
            state.token = '';
            state.status = '';
            sessionStorage.removeItem('token');
            Axios.get('/auth/logout')
        }
    },

};

const requestCancelModule = {
    state: {cancelTokenArr: []},
    mutations: {
        PUSH_CANCEL(state, payload) {
            state.cancelTokenArr.push(payload.cancelToken);
        },
        CLAER_CANCEL(state) {
            state.cancelTokenArr.forEach(item => {
                // item('路由跳转取消请求');
            });
            state.cancelTokenArr = [];
        }
    }
};

const keysModule = {
    state: {publicKey: '', privateKey: '', serverKey: ''},
    mutations: {
        set_key(state, pkcs) {
            let pkcsType = pkcs.pkcsType ? pkcs.pkcsType : 'pkcs8';//不为空则 设置为传入参数，为空则 设置为 pkcs8
            let pkcsSize = pkcs.pkcsSize || 1024;
            //1.创建RSA对象，并指定 秘钥长度
            var key = new NodeRSA({b: pkcsSize});
            key.setOptions({encryptionScheme: 'pkcs1'});//指定加密格式
            //2.生成 公钥私钥，使用 pkcs8标准，pem格式
            let publicDer = key.exportKey(pkcsType + '-public-pem');//制定输出格式
            publicDer = publicDer.replace(/\n/g, '');
            publicDer = publicDer.replace('-----BEGIN PUBLIC KEY-----', '');
            publicDer = publicDer.replace('-----END PUBLIC KEY-----', '');
            state.publicKey = publicDer;
            state.privateKey = key.exportKey(pkcsType + '-private-pem');
        },
        set_serverKey(state, serverKey) {
            state.serverKey = serverKey;
            sessionStorage.serverKey = serverKey;
        },
        del_key(state) {
            state.publicKey = '';
            state.privateKey = '';
            state.serverKey = '';
        }
    },
    actions: {
        getServerKey({commit, state}) {
            return new Promise((resolve, reject) => {
                Axios.get('/auth/safety/getPublicKey', {headers: {'Authorization': 'Basic c29sYXJwb2ludDpzb2xhcnBvaW50XzIwMjAwNzA5'}}).then(response => {
                    if (response.data.success) {
                        if (response.data.data) {
                            commit('set_serverKey', response.data.data);
                            resolve(response.data.data);
                        }
                    } else {
                        // this.$message({type:'warning',message:response.data.message})
                    }
                })
            })
        },
    }
};

const activeRouteModule = {
    state: {activeRoute: ''},
    mutations: {
        set_activeRoute(state, activeRoute) {
            state.activeRoute = activeRoute;
        },
    },
}


export default new Vuex.Store({
    modules: {
        token: tokenModule,
        requestCancel: requestCancelModule,
        keys: keysModule,
        activeRoute: activeRouteModule
    }
});


