<template>
</template>
<script type="application/javascript">
export default {
    data() {
        return {}
    },
    mounted() {
        console.log("%cSTOP!", "font-size:45px;color:red;font-weight:bold;");
        console.log("%c此浏览器功能专供开发者使用。若某人让你在此复制粘贴某内容以启用某平台功能或“入侵”某人帐户，此为欺诈！会使对方获权进入你的平台帐户，造成财产损失！", "font-size:25px;color:#ff9800;font-weight:bold;");
    }
}
</script>
<style scoped lang="scss">
</style>
