/**
 * 工具类
 */
// import Vue from 'vue'
import CryptoJS from 'crypto-js'

export default {
    //加密
    encrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
        var key = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        return encrypted.toString();
    },
    //解密
    decrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
        var key = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
        var decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    },
    //随机密码
    randomPwd(len) {
        var len = len || 32; //给len赋值为函数的参数，要是没有参数或者参数为0赋值为32
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz1234567890';
        var maxPos = $chars.length;//获取chars.length; //获取chars.length;//获取char的长度
        var pwd = ''; //初始化密码
        for (let i = 0; i < len; i++) {
            //Math.random()取值范围是（0，1）*maxPos取值范围是（0，maxPos的长度）Math.floor()函数是下取整，处理后取值范围为[0, maxPos-1],charAt()获取字符串该位置的值
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;//返回得到的字符串
    },
}
