import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LangStorage from './lang'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n)

const DEFAULT_LANG = 'zh_cn'
const locales = {
    zh_cn: Object.assign(require('./zh.json'), zhLocale)
}
const i18n = new VueI18n({
    locale: LangStorage.getLang(DEFAULT_LANG),
    messages: locales
})

export default i18n;
